<template>
    <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
        <v-card tile v-if="client">
            <v-toolbar color="primary" dark height="70px">
                <v-row align="center">
                    <v-col cols="2">
                        <v-img :src="imgIcon" contain max-width="40"/>
                    </v-col>
                    <v-col cols="8" class="text-truncate text-center">
                        {{ client.name }}
                    </v-col>
                    <v-col cols="2" align="right">
                        <v-btn icon @click="hide">
                            <v-icon v-text="'mdi-close'"/>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-toolbar>
            <v-card-text>
                <v-row class="mt-3">
                    <v-col cols="12" v-for="(response,i) in responses" :key="i">
                        <div>
                            <b>Doctor:</b> {{ response.doctor }}
                        </div>
                        <div>
                            <b>Fecha:</b> {{ response.date }}
                        </div>
                        <div>
                            <b>Decisión:</b> {{ response.type }}
                        </div>
                        <div>
                            <b>Respuesta:</b> <span v-html="response.text"></span>
                        </div>
                        <v-divider class="mt-3"/>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {STATUS} from "@/utils/Constants";

export default {
    name: "ResponseDialog",
    data() {
        return {
            dialog: false,
            client: null,
            responses: [],
        }
    },
    computed: {
        ...mapState(['dialogActive']),
    },
    methods: {
        ...mapMutations(['setDialogActive']),
        show() {
            const vm = this
            vm.dialog = true
            vm.setDialogActive(true)
        },
        hide() {
            const vm = this
            vm.dialog = false
            vm.setDialogActive(false)
            vm.client = null
        },
        setClient(client) {
            const vm = this
            vm.client = client
            vm.responses = vm.client.confirmed_hours.map(response => ({
                doctor: response.doctor?.name,
                date: vm.$moment(response.created_at).format('DD/MM/YYYY'),
                text: vm.formattedHtml(response.text),
                type: response.status === STATUS.APPROVE ? 'Candidato a cirugía' : 'Tratamiento Medico'
            }))
        },
        formattedHtml(value) {
            return value.replace(/\n/g, "<br>").replace(/\r/g, "<br>")
        }
    },
    watch: {
        dialogActive(val) {
            if (!val) this.hide()
        }
    }
}
</script>

<style scoped>

</style>